export class RouteBase {

    public static API_BASE = "/api/site";

    public static MODULO = {
        modulos: RouteBase.API_BASE+"/modulo/modulo", // RESOURCE
        banner: RouteBase.API_BASE+"/modulo/modulo-banner",
        unidade: RouteBase.API_BASE+"/modulo/unidade",
        fale_conosco: RouteBase.API_BASE+"/modulo/contato/fale-conosco",
        dica_roteiro: RouteBase.API_BASE+"/modulo/dica-roteiro",
        newsletter: RouteBase.API_BASE+"/modulo/newsletter",
        contato_consultor: RouteBase.API_BASE+"/modulo/contato/consultor",
        contato: RouteBase.API_BASE+"/modulo/contato/contato",
        contato_sugestao: RouteBase.API_BASE+"/modulo/contato/sugestao",
        newsletter_natal_luz: RouteBase.API_BASE+"/modulo/newsletter-natal-luz",
        produto_clasificacao: ""
    }
    public static CONTEUDO = {
        page: RouteBase.API_BASE+"/conteudo/page"
    }
    public static DESTINO = {
        url: RouteBase.API_BASE+"/destino"
    }
    public static ASSISTENCIA = {
        url: RouteBase.API_BASE+"/assistencia" 
    }
    public static PRODUTO = {
        produtos: RouteBase.API_BASE+"/componentes/produto/produtos",
        produto: RouteBase.API_BASE+"/componentes/produto/produto",
        search: RouteBase.API_BASE+"/componentes/produto/search",
        avaliacao: RouteBase.API_BASE+"/componentes/produto/avaliacao",
        avaliacoes: RouteBase.API_BASE+"/componentes/produto/avaliacao", 
        cidade: RouteBase.API_BASE+"/componentes/cidade",
        categorias: RouteBase.API_BASE+"/componentes/produto/categorias",
        aeroporto: {
            combo: RouteBase.API_BASE+"/componentes/produto/aeroporto/combo",
        },
        cia_aerea: {
            combo: RouteBase.API_BASE+"/componentes/produto/cia-aerea/combo",
        },
        send_contato: RouteBase.API_BASE+"/componentes/produto/send-contato",
        destino_produtos: RouteBase.API_BASE+"/componentes/produto/destino/produtos",
        regiao_produtos: RouteBase.API_BASE+"/componentes/produto/regiao/produtos",
        solicitacao_reserva: RouteBase.API_BASE+"/componentes/produto/solicitacao-reserva",
        favoritar: RouteBase.API_BASE+"/componentes/produto/favoritar"
    }
    public static CONTATO = {
        contato_viagem: RouteBase.API_BASE+"/modulo/contato/viagem",
        fale_conosco: RouteBase.API_BASE+"/modulo/contato/fale-conosco",
        trabalhe_conosco: RouteBase.API_BASE+"/modulo/contato/trabalhe-conosco",
        send: RouteBase.API_BASE+"/contato/contato"     
    }
    public static CART = {
        pagamento: RouteBase.API_BASE+"/componentes/cart/pagamento",
        base: RouteBase.API_BASE+"/componentes/cart", 
        lista_paxs: RouteBase.API_BASE+"/componentes/cart/lista-paxs", 
        abandonar_compra: RouteBase.API_BASE+"/componentes/cart/abandonar-compra", 
        esvaziar: RouteBase.API_BASE+"/componentes/cart/esvaziar",
        dados_comprador: RouteBase.API_BASE+"/componentes/cart/dados-comprador",
        get_cart_pagamento: RouteBase.API_BASE+"/componentes/cart/get-cart-pagamento",
        get_cart_file_gerado: RouteBase.API_BASE+"/componentes/cart/get-cart-file-gerado",
        update_passageiro_titular: RouteBase.API_BASE+"/componentes/cart/update-passageiro-titular"
    }
    public static MANAGE = {
        gerar_voucher: RouteBase.API_BASE+"/componentes/manage/gerar-voucher",
        consultar_reserva: RouteBase.API_BASE+"/componentes/manage/consultar-reserva",
        autenticar: RouteBase.API_BASE+"/componentes/manage/autenticar",
        logout: RouteBase.API_BASE+"/componentes/manage/logout",
        lista_tipo_servicos_by_id: RouteBase.API_BASE+"/componentes/manage/lista-tipos-servicos-by-id",
        regioes: RouteBase.API_BASE+"/componentes/manage/regioes",
        hoteis: RouteBase.API_BASE+"/componentes/manage/hoteis",
        origem: RouteBase.API_BASE+"/componentes/manage/origem",
        destino: RouteBase.API_BASE+"/componentes/manage/destino", 
        disponibilidade: RouteBase.API_BASE+"/componentes/manage/disponibilidade", 
        validar_disponibilidade: RouteBase.API_BASE+"/componentes/manage/validar-disponibilidade",
        update_passageiros: RouteBase.API_BASE+"/componentes/manage/update-passageiros" 
    }
    public static PAGAMENTO = { 
        finalizar: RouteBase.API_BASE+"/loja/pagamento/finalizar",
        finalizar_reserva: RouteBase.API_BASE+"/loja/pagamento/finalizar-reserva",
        enviar_confirmacao_email: RouteBase.API_BASE+"/loja/pagamento/enviar-confirmacao-email",
        dados_link_pagamento: RouteBase.API_BASE+"/loja/pagamento/dados-link-pagamento",
        finalizar_pagafacil: RouteBase.API_BASE+"/loja/pagamento/finalizar-pagafacil",
        update_dados: RouteBase.API_BASE+"/loja/pagamento/dados",
        update_passageiros: RouteBase.API_BASE+"/loja/pagamento/passageiros"
    }
    public static LOJA = { 
        usuario: RouteBase.API_BASE+"/loja/usuario",
        logout:  RouteBase.API_BASE+"/loja/logout",
        login:  RouteBase.API_BASE+"/loja/login", 
        login_social:  RouteBase.API_BASE+"/loja/login-social",
        email_senha: RouteBase.API_BASE+"/loja/email-senha",
        update_senha: RouteBase.API_BASE+"/loja/senha",
        cupom:{
            validar: RouteBase.API_BASE+"/loja/cupom/validar"
        },
        pagamento: {
            pagafacil: {
                url: RouteBase.API_BASE+"/loja/pagamento/pagafacil" 
            }
        },
        carrinho: {
            pagamento: RouteBase.API_BASE+"/loja/cart/pagamento",
            base: RouteBase.API_BASE+"/loja/carrinho", 
            lista_paxs: RouteBase.API_BASE+"/loja/carrinho/lista-paxs", 
            abandonar_compra: RouteBase.API_BASE+"/loja/carrinho/abandonar-compra", 
            esvaziar: RouteBase.API_BASE+"/loja/carrinho/esvaziar",
            dados_comprador: RouteBase.API_BASE+"/loja/carrinho/dados-comprador",
            get_cart_pagamento: RouteBase.API_BASE+"/loja/carrinho/get-cart-pagamento",
            get_cart_file_gerado: RouteBase.API_BASE+"/loja/carrinho/get-cart-file-gerado",
            update_passageiro_titular: RouteBase.API_BASE+"/loja/carrinho/update-passageiro-titular"  
        }
    }
    public static BLOG = {
        posts: {
            url: RouteBase.API_BASE+"/blog/posts"
        },
        post: {
            url: RouteBase.API_BASE+"/blog/post"
        }
    }
    public static TRADUCAO = {
        linguagem: {
            url: RouteBase.API_BASE+"/traducao/linguagem"
        }
    }

}
